<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.approve_m_e_report') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <ValidationProvider name="Garden Name" vid="garden_id">
                <b-form-group
                    label-for="garden_id"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('teaGardenConfig.garden_name')}}
                </template>
                <v-select
                    id="garden_id"
                    v-model="search.garden_id"
                    :reduce="op => op.value"
                    :options="gardenList"
                    label="text"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.select')"
                    >
                    </v-select>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
                <ValidationProvider name="Tentative Date" vid="tentative_date">
                <b-form-group
                    label-for="tentative_date"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('teaGardenBtriService.tentative_date')}}
                    </template>
                    <date-picker
                    id="date"
                    v-model="search.tentative_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click.prevent="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenPduService.approve_m_e_report') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" :to="{ name: 'tea_garden_service.pdu.approve_m_e_report' }"><i class="ri-arrow-go-back-fill"></i> {{ $t('globalTrans.back') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(application_no)="data">
                    <span v-if="data.item.application">{{data.item.application.application_no}}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    {{ getStatusName(data.item.status) }}
                  </template>
                  <template v-slot:cell(garden_name)="data">
                    {{ getGardenName(data.item.garden_id) }}
                  </template>
                  <template v-slot:cell(tentative_date)="data">
                    <span v-if="data.item.detail">{{data.item.detail.tentative_date | dateFormat}}</span>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { regularGarInsApproveGardenList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          garden_id: '',
          limit: 10
        },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'teaGardenService.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenConfig.garden_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
        { labels: 'teaGardenBtriService.tentative_date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 7, thStyle: { width: '12%' } }
      ],
      items: [],
      item: [],
      prepareItemId: [],
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_name' },
          { key: 'tentative_date' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_name' },
          { key: 'tentative_date' },
          { key: 'status' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    cloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    },
    visitGardenFormTitle () {
      return this.$t('teaGardenBtriService.visit_application')
    },
    statusList () {
        const arr = [
          { value: 1, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 2, text: this.$t('globalTrans.confirmed'), text_en: this.$t('globalTrans.confirmed', 'en'), text_bn: this.$t('globalTrans.confirmed', 'bn') },
          { value: 3, text: this.$t('globalTrans.approve'), text_en: this.$t('globalTrans.approve', 'en'), text_bn: this.$t('globalTrans.approve', 'bn') }
        ]
        return arr
    },
    gardenList () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
        return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, regularGarInsApproveGardenList + '/' + this.$route.params.id, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    }
  }
}
</script>
